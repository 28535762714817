$(document).ready(function() {
  console.log('Hi! Wenn du Lust hast mitzumachen, wir können deine Unterstützung gebrauchen, bitte melde dich bei garten@allmende-kontor.de');
  console.log('Hi! Want to help us? We can use it, please write us at garten@allmende-kontor.de');
});

$(document).on('click', '.user-garden-number', function(event) {
  event.preventDefault();
  let $span = $(this).find('span').addClass('d-none');
  let $input = $(this).find('input').removeClass('d-none');
  let $submit = $(this).find('button').removeClass('d-none');
});

$(document).on('click', '.submit-garden-number', function(event) {
  event.preventDefault();
  let num = parseInt($(this).prev('input').val());
  let userId = parseInt($(this).data('user'));
  $.ajax({
    url: '/set_garden_number/' + userId,
    type: 'POST',
    data: {id: userId, garden_number: num},
  })
  .done(function(data, textStatus, xhr) {
    console.log(data);
    if ( data.success ) {
      $('#user_garden_number_' + data.userId).addClass('d-none');
      $('#submit-garden-number-' + data.userId).addClass('d-none');
      $('#user_garden_number_span_' + data.userId).removeClass('d-none').text(data.beetnummer);
      toastr.success('Beetnummer geändert auf ' + data.beetnummer);
    } else {
      toastr.error('Nicht erlaubt / Fehlgeschlagen');
    }
  })
  .fail(function() {
    toastr.error('Nicht erlaubt / Fehlgeschlagen');
  })
  .always(function() {
    console.log("complete");
  });

});

$(document).on('click', '.user-garden-colorgroup', function(event) {
  event.preventDefault();
  //
  let $span = $(this).find('span').addClass('d-none');
  let $input = $(this).find('select').removeClass('d-none');
  let $submit = $(this).find('button').removeClass('d-none');
});

$(document).on('click', '.submit-colorgroup', function(event) {
  event.preventDefault();
  let num = parseInt($(this).prev('select').val());
  let userId = parseInt($(this).data('user'));
  $.ajax({
    url: '/set_colorgroup/' + userId,
    type: 'POST',
    data: {id: userId, colorgroup: num},
  })
  .done(function(data, textStatus, xhr) {
    console.log(data);
    if ( data.success ) {
      $('#user_garden_colorgroup_span_' + data.userId).parent('td').parent('tr').addClass('d-none');
      toastr.success('Farbgruppe geändert auf ' + data.colorgroup);
      //
    } else {
      toastr.error('Nicht erlaubt / Fehlgeschlagen');
    }
  })
  .fail(function() {
    toastr.error('Nicht erlaubt / Fehlgeschlagen');
  })
  .always(function() {
    console.log("complete");
  });

});

$(document).on('click', '.user-amount-paid', function(event) {
  event.preventDefault();
  let $span = $(this).find('span').addClass('d-none');
  let $input = $(this).find('input').removeClass('d-none');
  let $submit = $(this).find('button').removeClass('d-none');
});

$(document).on('click', '.submit-amount-paid', function(event) {
  event.preventDefault();
  let amount = parseInt($(this).prev('input').val());
  let userId = parseInt($(this).data('user'));
  $.ajax({
    url: '/set_amount_paid/' + userId,
    type: 'POST',
    data: {id: userId, amount: amount},
  })
  .done(function(data, textStatus, xhr) {
    console.log(data);
    if ( data.success ) {
      $('#user_amount_paid_' + data.userId).addClass('d-none');
      $('#submit-amount-' + data.userId).addClass('d-none');
      $('#user_amount_paid_span_' + data.userId).removeClass('d-none').text(data.amount);
      toastr.success('Betrag gesetzt');
    } else {
      toastr.error('Nicht erlaubt / Fehlgeschlagen');
    }
  })
  .fail(function() {
    toastr.error('Nicht erlaubt / Fehlgeschlagen');
  })
  .always(function() {
    console.log("complete");
  });

});

$('.update-user-paid').on('ajax:success', togglePaidBtn);
$('.update-user-active').on('ajax:success', toggleActiveBtn);

function togglePaidBtn(event) {
  let response = event.detail[0];
  if ( response.class == "success" ) {
    $('#paid_for_garden_' + response.userId).addClass('btn-success').removeClass('btn-light');
     $('#no_paid_for_garden_' + response.userId).addClass('btn-light').removeClass('btn-danger');
  } else {
    $('#paid_for_garden_' + response.userId).addClass('btn-light').removeClass('btn-success');
    $('#no_paid_for_garden_' + response.userId).addClass('btn-danger').removeClass('btn-light');
  }
}

function toggleActiveBtn(event) {
  let response = event.detail[0];
  if ( response.class == "success" ) {
    $('#activated_' + response.userId).addClass('btn-success').removeClass('btn-light');
     $('#deactivated_' + response.userId).addClass('btn-light').removeClass('btn-danger');
  } else {
    $('#activated_' + response.userId).addClass('btn-light').removeClass('btn-success');
    $('#deactivated_' + response.userId).addClass('btn-danger').removeClass('btn-light');
  }
}
